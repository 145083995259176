import { auth } from 'modules/auth'
import { store } from '_core'
import { isJwtExpired } from 'jwt-check-expiration'

// Функция извлечения токена авторизации из локального хранилища
// Переход на страницу авторизации, если токена нет либо токен не прошел проверку
export const getToken = function() {
  const token = localStorage.getItem(auth.TOKEN)
  if (!token || (token && isJwtExpired(token))) {
    const { dispatch } = store
    dispatch(auth.logout())
  }
  return token
}
