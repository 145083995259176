import React from 'react'
import io from 'socket.io-client'
import { getToken } from '_core'
import PropTypes from 'prop-types'

const INFORMER_SOCKET = `${process.env.REACT_APP_GATEWAY_URL ||
  ''}/informer-client`

class InformerSocket extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      socket: false,
    }
    this.onError = this.onError.bind(this)
    this.onLogin = this.onLogin.bind(this)
    this.onMessage = this.onMessage.bind(this)
    this.login = this.login.bind(this)
  }
  onError(error) {
    const { onError } = this.props
    const { socket } = this.state
    if (onError) {
      onError(error, socket)
    } else {
      console.error(error)
    }
  }
  onLogin(data) {
    const { onLogin } = this.props
    const { socket } = this.state
    if (onLogin) {
      onLogin(data, socket)
    } else {
      console.log(data)
    }
  }
  onMessage(data) {
    const { onMessage } = this.props
    const { socket } = this.state
    if (onMessage) {
      onMessage(data, socket)
    } else {
      console.log(data)
    }
  }
  login(socket) {
    const token = getToken()
    socket.emit('login:request', { token })
  }
  componentDidMount() {
    const socket = io(INFORMER_SOCKET)
    socket.on('error', this.onError)
    socket.on('login:response', this.onLogin)
    socket.on('message', this.onMessage)
    socket.on('login:request', () => {
      this.login(socket)
    })
    socket.on('reconnect', () => {
      this.login(socket)
    })
    this.setState({ socket })
    this.login(socket)
  }
  componentWillUnmount() {
    const { socket } = this.state
    socket && socket.disconnect && socket.disconnect()
    this.setState({ socket: false })
  }
  render() {
    return <div>{this.props.children}</div>
  }
}

InformerSocket.propTypes = {
  onError: PropTypes.func,
  onLogin: PropTypes.func,
  onMessage: PropTypes.func,
  onBroadcast: PropTypes.func,
}

export { InformerSocket }
